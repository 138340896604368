<template>
	<!--				MOBILE NAV -->
	<mobile-nav class="lg:hidden" />
	<desktop-nav class="hidden md:block" />
	<div class="mt-12 lg:mt-20">
		<slot />
	</div>
	<div class="mt-32">
		<div class="border-t border-gray-200 bg-white py-32 text-center">
			<logo class="mx-auto w-48" />
			<h1 class="mt-6 text-5xl font-bold text-black">Try Scholarly</h1>
			<div class="mt-5 text-xl text-gray-500">
				<p>It's completely free, simple to use, and easy to get started.</p>
				<p>Join thousands of students and educators today.</p>
			</div>
			<div class="mt-10">
				<nuxt-link
					class="rounded-full bg-primary px-8 py-4 text-xl font-bold text-white transition-all hover:opacity-90"
					to="/register">
					Start Learning for Free
				</nuxt-link>
			</div>
			<p class="mt-12 text-xl text-gray-500">
				Are you a school or organization?
				<a
					class="underline"
					href="mailto:hello@scholarly.so"
					>Contact us</a
				>
			</p>
		</div>
		<div class="bg-gray-50 py-24">
			<div class="constraints flex-row justify-between space-y-12 lg:flex lg:space-y-0">
				<div>
					<nuxt-link to="/">
						<logo class="w-48" />
					</nuxt-link>
					<p class="mt-2 text-gray-500">© 2024 Scholarly. All rights reserved.</p>
				</div>
				<div>
					<h1 class="font-bold">Social</h1>
					<div class="mt-2 flex flex-col text-gray-500">
						<a
							href="https://twitter.com/usescholarly"
							target="_blank">
							Twitter
						</a>
						<a
							href="https://scholarly.so/discord"
							target="_blank">
							Discord
						</a>
						<a
							href="https://www.youtube.com/@usescholarly"
							target="_blank">
							Youtube
						</a>
						<a
							href="https://www.reddit.com/r/usescholarly/"
							target="_blank">
							Reddit
						</a>
					</div>
				</div>
				<div>
					<h1 class="font-bold">Resources</h1>
					<div class="mt-2 flex flex-col text-gray-500">
						<nuxt-link to="/pricing">Pricing</nuxt-link>
						<nuxt-link to="/blog">Blog</nuxt-link>
						<nuxt-link to="/topic/guide"> Guides & Tutorials</nuxt-link>
						<nuxt-link to="/topic/updates"> Updates</nuxt-link>
						<nuxt-link to="/faq"> FAQ</nuxt-link>
						<nuxt-link to="mailto:hello@scholarly.so"> Contact Us</nuxt-link>
					</div>
				</div>
				<div>
					<h1 class="font-bold">Features</h1>
					<div class="mt-2 flex flex-col text-gray-500">
						<nuxt-link to="/features/notes">Note Taking</nuxt-link>
						<nuxt-link to="/features/flashcards">Flashcards</nuxt-link>
						<nuxt-link to="/features/ai">AI</nuxt-link>
					</div>
				</div>
				<div>
					<h1 class="font-bold">Legal</h1>
					<div class="mt-2 flex flex-col text-gray-500">
						<nuxt-link to="/docs/privacy">Privacy Policy</nuxt-link>
						<nuxt-link to="/docs/tos">Terms of Service</nuxt-link>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script lang="ts" setup></script>
