<template>
	<button
		class="cursor-select relative flex items-center space-x-2 text-lg hover:bg-gray-100 rounded-md px-4 py-2 transition-colors"
		@click="open = !open">
		<span class="font-semibold">
			{{ button_text }}
		</span>
		<animated-chevron-icon
			:mode="!open ? 'down' : 'up'"
			class="h-4 w-4" />
		<menu-transition>
			<dropdown
				v-if="open"
				:width="dropdown_width"
				class="-left-5 top-0 mt-10"
				@close="open = false">
				<slot />
			</dropdown>
		</menu-transition>
	</button>
</template>
<script lang="ts" setup>
const open = ref(false);

defineProps({
	button_text: {
		type: String,
		required: true,
	},
	hoverable: {
		type: Boolean,
		default: true,
	},
	dropdown_width: {
		type: String,
		default: "400px",
	},
});

let timeout: NodeJS.Timeout | null = null;

function close() {
	if (timeout) return;

	timeout = setTimeout(() => {
		open.value = false;
		timeout = null;
	}, 50);
}

function cancelClose() {
	if (timeout) {
		clearTimeout(timeout);
		timeout = null;
	}
}
</script>
